@import url('https://fonts.googleapis.com/css?family=Poppins|Poiret+One&display=swap');
html {
  height: 100%;
}
body {
  --off-black: #1B1B1B;
  --dark-grey: #222222;
  --medium-dark-grey: #4C4C4C;
  --medium-grey: #9C9C9C;
  --light-grey: #E0E0E0;
  --off-white: #FAFAFA;
  --link-accent: #E8293A;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--off-white);
  color: var(--dark-grey);
  font-family: 'Poppins', sans-serif;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}
body::after {
  content: '';
  display: block;
  height: 58px; /* Set same as footer height */
}
a {
  color: var(--link-accent);
  transition: color 500ms;
  text-decoration: none;
}
a:hover{
  color: var(--dark-grey);
}
.page-wrapper{
  background: var(--off-white);
}
